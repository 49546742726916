<template>
  <app-select
    v-on="$listeners"
    v-bind="$attrs"
    hide-details="auto"
    dense
    multiple
    chips
    :item-text="(val) => val.short_name"
    :items="employees"
    :value-comparator="comparator"
    :item-value="(val) => val"
  />
    <!-- return-object -->
</template>

<script>
export default {
  
  data: () => ({
    employees: [],
  }),

  created() {
    this.select();
  },

  methods: {

    comparator(a, b) {
      return a.id == b.id;
    },

    async select() {
      let response = await this.$http.index("employee/employee-report");
      this.employees = response.employees;
    },
  },
};
</script>

<style>
</style>